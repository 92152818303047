<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16" fill="#5B5B5B">
              <path d="M2.5 2C2.222656 2 2 2.222656 2 2.5L2 4.5C2 4.777344 2.222656 5 2.5 5L4.5 5C4.777344 5 5 4.777344 5 4.5L5 2.5C5 2.222656 4.777344 2 4.5 2 Z M 3 3L4 3L4 4L3 4 Z M 7 3L7 4L14 4L14 3 Z M 2.5 6C2.222656 6 2 6.222656 2 6.5L2 8.5C2 8.777344 2.222656 9 2.5 9L4.5 9C4.777344 9 5 8.777344 5 8.5L5 6.5C5 6.222656 4.777344 6 4.5 6 Z M 3 7L4 7L4 8L3 8 Z M 7 7L7 8L14 8L14 7 Z M 2.5 10C2.222656 10 2 10.222656 2 10.5L2 12.5C2 12.777344 2.222656 13 2.5 13L4.5 13C4.777344 13 5 12.777344 5 12.5L5 10.5C5 10.222656 4.777344 10 4.5 10 Z M 3 11L4 11L4 12L3 12 Z M 7 11L7 12L14 12L14 11Z" fill="#5B5B5B" />
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">Job List</h3>
          </div>
          <div class="flex justify-center">
            <md-button @click.native="exportASCsv()" class="bg-victoria text-white text-uppercase rounded"><i class="flaticon-reading-book mr-2"></i> Export as CSV</md-button>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="md-layout md-gutter">
          <div class="md-layout-item mr-5">
            <div class="mb-4 flex flex-wrap justify-xs-center">
              <div class="relative">
                <Datepicker 
                placeholder="Select Date"
                :value="addedAt" 
                :format="customFormatter"
                @selected="getAddedAt" 
                :clearButton="true"
                input-class="w-56 m-1 py-3 pl-3 rounded outline-gray-400"
                />
                <svg class="w-5 date-picker-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                  <path d="M5 0L5 4L7 4L7 0 Z M 17 0L17 4L19 4L19 0 Z M 1 3C0.449219 3 0 3.449219 0 4L0 7C0 7.550781 0.449219 8 1 8L1 24L23 24L23 8C23.550781 8 24 7.550781 24 7L24 4C24 3.449219 23.550781 3 23 3L20 3L20 5L16 5L16 3L8 3L8 5L4 5L4 3 Z M 3 8L21 8L21 22L3 22 Z M 5 10L5 12L7 12L7 10 Z M 9 10L9 12L11 12L11 10 Z M 13 10L13 12L15 12L15 10 Z M 17 10L17 12L19 12L19 10 Z M 5 14L5 16L7 16L7 14 Z M 9 14L9 16L11 16L11 14 Z M 13 14L13 16L15 16L15 14 Z M 17 14L17 16L19 16L19 14 Z M 5 18L5 20L7 20L7 18 Z M 9 18L9 20L11 20L11 18 Z M 13 18L13 20L15 20L15 18 Z M 17 18L17 20L19 20L19 18Z" fill="#5B5B5B" />
                </svg>
              </div>
              <SelectComponent
                  :items="jobType"
                  :placeholder="false"
                  @input="getJobType"
                  class="w-56 m-1" />

              <SelectComponent
                  :items="ApplicantType"
                  :placeholder="false"
                  @input="getApplicantType"
                  class="w-56 m-1" />

              <SearchBox 
                  placeholder="Search"
                  class="w-56 p-2 m-1 absolute right-0 mr-3"
                  v-model="keyword"
                  v-if="show"
              />
            </div>
            <md-divider></md-divider>

            <Table :items="jobList" hover-action>
              <template slot="row" slot-scope="{ item }">
                <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
                <md-table-cell md-sort-by="jobId" md-label="JOB ID" class="text-victoria">{{ item.jobId }}</md-table-cell>
                <md-table-cell md-sort-by="jobTitle" md-label="JOB TITLE">{{ showFixedLengthString(item.jobTitle) }}</md-table-cell>
                <md-table-cell md-sort-by="addedAt" md-label="ADDED AT">{{ item.addedAt }}</md-table-cell>
                <md-table-cell md-sort-by="deadLine" md-label="DEADLINE">{{customFormatter(item.deadLine)}}</md-table-cell>
                <md-table-cell md-sort-by="numberOfApplicant" md-label="NO. OF APPL.">{{ item.application_count }}</md-table-cell>
                <md-table-cell md-sort-by="status" class="text-green font-bold" md-label="STATUS">{{ item.status }}</md-table-cell>
                <span class="action">
                  <a v-if="show" :href="jobUrl(item.jobId)"><md-icon  class="bg-primary rounded-full p-1 text-white text-base mx-1">add</md-icon></a>
                  <!-- <a href="{{'job-circular/'+item.jobId}}">Apply</a> -->
                  <md-icon v-if="show" class="bg-heather rounded-full p-1 text-white text-base mx-1" @click.native="onShowDetails(item)">visibility</md-icon>
                  <!-- <md-icon v-if="show" class="bg-victoria rounded-full p-1 text-white text-base mx-1" @click.native="onShowEdit(item)">edit</md-icon> -->
                  <md-icon v-if="show" class="bg-danger rounded-full p-1 text-white text-base mx-1" @click.native="deleteJob(item.jobId)">delete</md-icon>
                  <!-- <md-icon v-if="show" class="bg-tahiti-gold rounded-full p-1 text-white text-base mx-1" @click.native="onShowEdit">share</md-icon> -->
                  <md-icon v-if="show" class="bg-success rounded-full p-1 text-white text-base mx-1" @click.native="onShowApplicantList(item.jobId)">chevron_right</md-icon>
                  <md-icon :class="[display]" class="bg-success rounded-full p-1 text-white text-base mx-1 ml-166" @click.native="onHideApplicantList()">chevron_left</md-icon>
                </span>
              </template>
            </Table>
            <div class="flex justify-content-center align-center mt-8">
                <Paginate
                      :start="meta.current_page"
                      :end="meta.last_page"
                      :total="meta.last_page"
                      :limit="meta.per_page"
                      @on-start="onStart"
                      @on-end="onEnd"
                  />
              </div>
          </div>
         
          <div class="md-layout-itemmd" :class="[display]">
            <div class="mb-4 flex flex-wrap justify-xs-center">
                <SearchBox
                    placeholder="Search"
                    class="w-full p-2 m-1 right-0"
                    v-model="keyword"
                />
              </div>
              <md-divider></md-divider>
              <Table :items="applicantList" hover-action>
                <template slot="row" slot-scope="{ item }">
                  <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
                  <md-table-cell md-sort-by="name" md-label="APPLICANT NAME" class="text-victoria">{{ item.name }}</md-table-cell>
                  <md-table-cell md-sort-by="point" md-label="POINT">{{ item.point }}</md-table-cell>
                  <md-table-cell md-sort-by="status" class="text-green font-bold" md-label="STATUS">{{ item.status }}</md-table-cell>
                  <!-- <span class="action">
                    <md-icon class="bg-heather rounded-full p-1 text-white text-base mx-1 ml-180" @click.native="onShowDetails(item)">visibility</md-icon>
                  </span> -->
                </template>
              </Table>
              <!-- :limit="applicantMeta.per_page" -->
              <div class="flex justify-content-center align-center mt-8">
                <Paginate
                      :start="applicantMeta.current_page"
                      :end="applicantMeta.last_page"
                      :total="applicantMeta.last_page"
                      :limit="applicantMeta.per_page"
                      @on-start="onStartApplicant"
                      @on-end="onEndApplicant"
                  />
              </div>
          </div>
        </div>

        <div @click="onShowCreate" style="position: absolute; bottom: 0px; right: 0"
             class="bg-victoria pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white">add</md-icon>
        </div>
      </CardBody>
    </Card>
    <Dialog>
      <component :content="content" :is="component"></component>
    </Dialog>
    <ConfirmDialog 
      @confirm="actDeleteJob(jobId)"
      :active.sync="active"
    />
  </div>
</template>

<script>
import {
  Card,
  Table,
  Dialog,
  CardBody,
  Paginate,
  SearchBox,
  SelectComponent,
  ConfirmDialog,
} from "@/components";
import { JobDetailsView, JobCreate, JobEdit } from "@/components/molecule";
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import cms from "@/data/cms";
import { query } from "@/utils/queryString";
import { mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {
    Card,
    Table,
    Dialog,
    Paginate,
    CardBody,
    SearchBox,
    JobEdit,
    JobDetailsView,
    JobCreate,
    SelectComponent,
    Datepicker,
    ConfirmDialog,
  },
  data() {

    return {
      active: false,
      currentIndex: 0,
      component: 'JobCreate',
      sortBy: cms.sortBy,
      jobType: cms.jobType,
      ApplicantType: cms.ApplicantType,
      content: null,
      keyword: '',
      selectedKeyword: '',
      query: '?',
      visibility: true,
      pagination: true,
      selectedValue: null,
      itemIndex: null,
      status: '',
      addedAt: '',
      addedAtDateclear: false,
      // addedAt: new Date(),
      jobId:'',
      searchParams : new URLSearchParams(),
      buttonFilters : cms.buttonFilters,
      display:'md-hide',
      arrow:'chevron_right',
      show:true
    }
  },
  computed: {
      ...mapGetters({
        jobList: "job/jobList",
        meta: "job/jobMeta",
        applicantMeta: "applicant/applicantMeta",
        applicantList: "applicant/applicantList",
      }),
  },
  watch: {
    keyword(oldvalue, newValue) {
        query.set('search', oldvalue)
        // For reseting search result
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
          this.actGetJobList(`?${query.get()}`);
        }
        // For submitting search api after 3 cherecter
        if(oldvalue.length >= 3) {
          this.actGetJobList(`?${query.get()}`);
        }
    },
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actGetJobList: 'job/actGetJobList',
      actDeleteJob: "job/actDeleteJob",
      actGetApplicantListByJobId: 'applicant/actGetApplicantListByJobId',
    }),

    jobUrl(id){
      return window.location.origin+'/job-circular/'+id;
    },
    showFixedLengthString(string, count=70){
      return string.slice(0, count) + (string.length > 10 ? "..." : "")
    },
    customFormatter(date) {
      if(date)
      return moment(date).format('D MMM, YYYY. ddd');
    },

    deleteJob(jobId) {
      this.active = true;
      this.jobId = jobId;
    },
    getAddedAt(date) {

      this.selectedValue = date;
      if(this.selectedValue == '' || this.selectedValue == null) {
        query.remove('posting_date');
      }else {
        this.selectedValue = moment(this.selectedValue).format('YYYY-MM-D');
        query.set('posting_date', this.selectedValue);
      }
      this.actGetJobList(`?${query.get()}`);
    },

    getJobType(value) {
      
      this.selectedValue = value;
      if(this.selectedValue == 'all') {
        query.remove('type');
      }else {
        query.set('type', this.selectedValue);
      }
      this.actGetJobList(`?${query.get()}`);
    },

    getApplicantType(value) {

      this.selectedValue = value;
      if(this.selectedValue == 'all') {
        query.remove('job_position');
      }else {
        query.set('job_position', this.selectedValue);
      }
      this.actGetJobList(`?${query.get()}`);
    },
    
    exportASCsv(){
      alert('Coming Soon!')
    },

    onShowDetails(item) {
      this.content = item;
      this.component = 'JobDetailsView';
      this.dialog(true);
    },

    onShowCreate() {
      this.component = 'JobCreate';
      this.dialog(true);
    },

    onShowApplicantList(jobId) {
      this.display = "md-show";
      this.show = false;
      this.jobId = jobId
      this.actGetApplicantListByJobId(jobId)
    },
    onHideApplicantList(){
      this.show = true;
      this.display = "md-hide";
    },
    onShowEdit(item) {
      this.content = item;
      this.component = 'JobEdit';
      this.dialog(true);
    },
    onStart(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actGetJobList(`?${query.get()}`);
    },
    onEnd(value) {
   
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actGetJobList(`?${query.get()}`);
      
    },
    onStartApplicant(value) {
      
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actGetApplicantListByJobId(this.jobId`?${query.get()}`);
    },
    onEndApplicant(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actGetApplicantListByJobId(this.jobId`?${query.get()}`);
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },

   created() {
    this.actGetJobList()
  }
}

</script>
<style>
    .on-hover .action{
      position: relative!important;
      margin-left: -210px!important;
      top: 12px!important;
    }
    .vdp-datepicker__clear-button{
      position: absolute;
      margin-left: -15px;
      margin-top: 15px;
      padding: -2px;
      font-weight: bold;
      color: red;
    }
    .date-picker-icon {
        top: 15px!important;
        right: 15px!important
    }
    .justify-content-center{
      justify-content: center;
    }
  
</style>